import React from "react";
import Layout from "../components/Layout";
import fire from "../images/env/fireicon.svg";
import plus from "../images/env/plusicon.svg";
import flask from "../images/env/flaskicon.svg";
import leaf from "../images/env/leaficon.svg";
import "../styles/global.css";
import "../styles/pageHeader.css";
import "../styles/environment.css";

function environment() {
  return (
    <Layout>
      <main className="page__container">
        <h1 className="page__title">Environment</h1>
        <article class="content__frame env__wrapper">
          <h2>Biodegradable Foams</h2>
          <p>
            Since environmental protection is a priority for us, we have
            developed foams that are
          </p>
          <ul>
            <li>
              Certified non-PBT (non-Persistent, non-Bioaccumulative, non-Toxic)
            </li>
            <li>Fluorine-free</li>
            <li>Made of natural substances, even edible</li>
            <li>Biodegradable</li>
          </ul>
          <p>
            Pi Foam System’s firefighting power can be delivered via any foam
            that meets system specifications — whether it is our
            environment-friendly foam or a traditional variety.
          </p>
          <h2>
            Foam formula and system components tailored for optimal performance
            in all conditions
          </h2>
          <p>
            Not all combustible liquids are created equal. When firefighters
            combat flames on liquids with low boiling points, the fluid may
            continue to evaporate even beneath the foam layer. If the boiling
            point is high, the foam may evaporate before the liquid does,
            exposing the fluid to additional hazards such as boilover.
            Firefighters might try to defeat such blazes by ramping up the foam
            intensity — but if the foam is not up to the job, this often proves
            useless. Swiss Fire Protection’s solution is to develop foam
            formulas that are expressly designed for putting out fires on tricky
            liquids, even in the most extreme climactic conditions. And the Pi
            Foam System reliably distributes foam with an intensity that
            extinguishes even the toughest fires. We have developed an ideal
            instant-foam formula and pressure for each of the following
            industries
          </p>
          <div className="content__icons">
            <div>
              <img src={fire} alt="oil icon" />
              <p>Oil industry</p>
            </div>
            <div>
              <img src={plus} alt="chemical icon" />
              <p>Pharmaceutical industry</p>
            </div>
            <div>
              <img src={flask} alt="flask icon" />
              <p>Chemical industry</p>
            </div>
            <div>
              <img src={leaf} alt="leaf icon" />
              <p>Vegetable-oil industry</p>
            </div>
          </div>
        </article>
        <article className="env__wrapper">
          <h2>
            Our mission is to revolutionize firefighting while eliminating
            environmental threats
          </h2>
          <p>
            Until recently, tank-firefighting systems have not given sufficient
            priority to environmental protection. Contamination from flue gas,
            dangerous substances, toxic-foam spillage, and fumes was considered
            inevitable, so authorities have imposed neither criminal nor
            financial penalties in relation to environmental damage. Imagine the
            environmental impact of a weeklong storage-tank fire that can even
            be seen from outer space! Our System limits smoke and combustion to
            a maximum of π minutes.
          </p>

          <p>
            The fluorinated hydrocarbons in firefighting foam cause long-term
            soil contamination that can far outweigh the environmental damage
            from air pollution. We offer biodegradable foam instead of toxic
            traditional foams. Unlike traditional foam-shooting methods, Pi Foam
            is applied directly onto the fire and nowhere else. No foam
            spillage, no soil contamination. Our System prevents catastrophe and
            minimizes the impact of any fire event in the petrochemical sector.
          </p>

          <p>
            Theoretically, everyone has the right to claim innocence by arguing
            that their fire-prevention system has all the official seals and
            complies with current recommendations, standards, and laws.
            Unfortunately, technical specifications often lag behind technical
            innovation. Authorities may not force manufacturers and operators to
            replace ineffective technologies. Makers of traditional firefighting
            equipment may come out with new and improved devices, but these
            still cannot extinguish flames in a reasonable amount of time.
          </p>

          <p>
            Although firefighters around the world perform heroic work, the
            profession’s approach to technology is sometimes problematic.
            State-of-the-art equipment inevitably becomes outdated in just a few
            years, meaning firefighters may someday confront a blaze they can
            never put out. In many cases, the best they can do is to “keep the
            fire under control,” which simply means allowing it to burn until it
            consumes all the combustible material. With the Pi Foam System, the
            tank remains intact. There is no damage to the environment and no
            risk to human life. It may not be an exaggeration to say that true
            “control” means extinguishing fires in less than π minutes using the
            Pi Foam System.
          </p>
        </article>
      </main>
    </Layout>
  );
}

export default environment;
